.map {
    height: 650px;
    margin-top: 0 !important;
    padding-top: 5rem !important;
    background: $color-white;

    &.s {
        height: calc(450px + 10rem);

        .map-container {
            width: 100%;
            display: flex;
        }
    }

    .map-container {
        height: 650px;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;

        &__item {
            width: calc(50% - 1rem);

            &:first-of-type {
                margin-right: 2rem;
            }
        }
    }

    .map-detail-container {
        position: absolute;
        right: 0;
        top: 0;
        background: $color-white;
        border-radius: 5px;
        //overflow: hidden;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
        color: $color-black;
        width: 400px;
        min-height: 500px;

        .map-detail__img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 200px;
        }

        .map-detail__content {
            margin-top: 200px;
            padding: 3rem;

            h2 {
                font-size: 2.2rem;
                margin: 0 0 1rem 0;
            }

            h3 {
                display: flex;
                align-items: center;
                margin-bottom: 2rem;

                img {
                    height: 2rem;
                    margin-right: 1rem;
                }

                span {
                    font-size: 1.5rem;
                    font-weight: 300;
                    text-align: left;
                }
            }

            p {
                font-size: 1.4rem;
                line-height: 2.2rem;
                margin-bottom: 2rem;
            }
        }
    }
}

@media (max-width: 768px) {

    .map {
        padding-bottom: 2rem !important;
        height: calc(900px + 10rem + 2rem);

        .wrapper {
            height: 100%;
        }

        .map-container {
            flex-wrap: wrap;

            &__item {
                width: 100%;
                margin-right: 0 !important;
                margin-bottom: 2rem;

                &:nth-of-type(2) {
                    margin-bottom: 0;
                }
            }
        }

        .map-detail-container {
          margin-left: 1rem;
          margin-right: 1rem;
          width: calc(100% - 2rem) !important;
          top: unset;
          bottom: 0;
        }
    }
}
