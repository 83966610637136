.organizator-container {
  margin-left: 10rem;
  margin-bottom: 4rem;

  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 768px) {

  .organizator-container {
    margin-left: 6rem;
  }
}
